require.context('../images', true);

import "core-js/stable";
import "regenerator-runtime/runtime";

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()

import 'bootstrap/dist/js/bootstrap';
import 'select2/dist/js/select2.min';
import 'metismenu/dist/metisMenu.min';

import 'jquery-slimscroll/jquery.slimscroll';
import '../src/javascripts/inspinia';

import './libs'

if (window.vapidPublicKey && navigator.serviceWorker) {
  navigator.serviceWorker.register('/service-worker.js')
    .then(function(reg) {
      console.log('Service worker change, registered the service worker')
    })
    
  window.Notification.requestPermission().then(permission => {    
    if(permission !== 'granted'){
      throw new Error('Permission not granted for Notification')
    }
  })
  
  navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
    serviceWorkerRegistration.pushManager
      .subscribe({
        userVisibleOnly: true,
        applicationServerKey: window.vapidPublicKey
      })
      .then(function(subscription) {
        const csrfToken = document.getElementsByName('csrf-token')[0].content;
        
        fetch('/subscribe', {
          method: 'post',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'include',
          body: JSON.stringify({ subscription: subscription.toJSON() }),
          headers: {
            'X-CSRF-Token': csrfToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }).then((res) => {
          if (res.status === 201) {
            console.log("Post successfully created!")
          }
        }).catch((error) => {
          console.log(error)
        })
      })
  })
} else {
  console.error('Service worker is not supported in this browser')
}